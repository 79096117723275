import React, { FC, useState } from 'react';
import { Box, Button, Container, Typography } from '@mui/material';
import { CustomTextField } from './fields/CustomTextField';
import { loginRequest } from '../services/auth';
import { useNavigate } from 'react-router-dom';

interface LoginFormProps {
    setAuthenticate: (value: boolean) => void;
}

export const LoginForm: FC<LoginFormProps> = ({ setAuthenticate }) => {
    const navigate = useNavigate();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    const handleLogin = async () => {
        if (!email) {
            setError('Please enter a valid email');
            return;
        }
        if (!password) {
            setError('Please enter a valid password');
            return;
        }

        const response = await loginRequest(email, password);

        if (response.token) {
            setAuthenticate(true);
            navigate('/');
        } else {
            setError(response.error ?? 'Something went wrong');
        }
    };
    return (
        <Container maxWidth="sm">
            <Box
                mt={20}
                p={8}
                sx={{
                    background:
                        'linear-gradient(to bottom, rgba(12, 260, 440, 0.6), rgba(12, 26, 44, 0.6))'
                }}
                borderRadius={4}
                boxShadow={3}>
                <Typography
                    variant="h4"
                    align="center"
                    gutterBottom
                    color="white"
                    fontWeight={'bolder'}>
                    Login
                </Typography>
                <CustomTextField
                    required={true}
                    label="Email"
                    variant="outlined"
                    fullWidth
                    margin="dense"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <CustomTextField
                    required={true}
                    label="Password"
                    type="password"
                    variant="outlined"
                    fullWidth
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    sx={{ marginTop: '20px' }}
                />
                <Button
                    variant="contained"
                    // color="primary"
                    fullWidth
                    onClick={handleLogin}
                    sx={{
                        mt: 4,
                        background: 'rgba(12, 260, 440, 0.3)',
                        borderRadius: '10px',
                        padding: '15px'
                    }}>
                    Login
                </Button>
                {error && (
                    <Typography color="error" align="center" sx={{ mt: 2 }}>
                        {error}
                    </Typography>
                )}
            </Box>
        </Container>
    );
};

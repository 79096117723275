import React from 'react';
import { Box, Card, CardContent, CardMedia, Typography } from '@mui/material';
import { ToolType } from './tools';
import { toolRedirect } from '../../services/tool-redirect';

interface ToolCardProps {
    tool: ToolType;
}

export const ToolCard: React.FC<ToolCardProps> = ({ tool }) => {
    const { image, title, status, url } = tool;

    return (
        <Card
            sx={{
                height: '100%',
                background: 'linear-gradient(to bottom, #77f2ff, #061b35)',
                color: 'white',
                textAlign: 'center',
                boxShadow: 'none',
                position: 'relative',
                overflow: 'hidden',
                borderRadius: '10px',
                transition: 'transform 0.3s ease-in-out',
                '&:hover':
                    status === 'active'
                        ? {
                              transform: 'scale(1.07)',
                              cursor: 'pointer'
                          }
                        : { transform: 'scale(1)', cursor: 'no-drop' }
            }}
            onClick={() => toolRedirect(url)}>
            {status === 'comingSoon' && (
                <Box
                    sx={{
                        position: 'absolute',
                        top: '0px',
                        right: '-10px',
                        background: '#8cd946',
                        padding: '13px 10px 10px 10px',
                        borderRadius: '0px 10px',
                        fontWeight: 'bold',
                        color: '#000',
                        fontSize: '14px',
                        zIndex: '100',
                        width: '50%',
                        boxShadow: '-3px 6px 20px 13px rgba(0, 0, 0, 0.8)'
                    }}>
                    Coming Soon
                </Box>
            )}
            <CardContent sx={{ height: '100%' }}>
                <CardMedia
                    component="img"
                    image={image}
                    alt={title}
                    sx={{
                        maxWidth: '100%',
                        borderRadius: '10px',
                        opacity: 1
                    }}
                />
                <Box
                    sx={{
                        height: '4px',
                        background: '#4680c9 !important',
                        width: '80%',
                        margin: {
                            xs: '30px auto 20px auto',
                            sm: '20px auto 20px auto'
                        }
                    }}
                />
                <Typography
                    variant="h6"
                    component="h3"
                    fontFamily={
                        "Avenir, Montserrat, Corbel, 'URW Gothic', source-sans-pro, sans-serif"
                    }>
                    {title}
                </Typography>
            </CardContent>
        </Card>
    );
};

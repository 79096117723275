import React, { FC, ReactNode } from 'react';
import { Box } from '@mui/material';

interface Props {
    children: ReactNode;
}

export const MainWrapper: FC<Props> = ({ children }) => {
    return (
        <Box
            sx={{
                background:
                    'linear-gradient(to bottom, rgba(0, 0, 0, 0.4), rgba(12, 26, 44, 0.8)), url(/images/background.jpeg) center/cover no-repeat',
                minHeight: '100vh'
            }}>
            {children}
        </Box>
    );
};

import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';

export const CustomTextField = styled(TextField)({
    // Base styles
    backgroundColor: 'rgba(12, 26, 44, 0.6)',
    borderRadius: '10px',
    color: 'white !important',
    border: 'solid 1px rgba(12, 26, 44, 0.6)',

    // Input base styles
    '& .MuiInputBase-root': {
        backgroundColor: 'transparent',
        transition: 'all 0.3s ease',
        color: 'white',
        letterSpacing: '1.2px'
    },

    // Label styles
    '& .MuiInputLabel-root': {
        color: 'white',
        '&.Mui-focused': {
            color: 'white' // Primary color when focused
        }
    },

    // Outlined input styles
    '& .MuiOutlinedInput-root': {
        // Default state
        '& fieldset': {
            borderColor: 'rgba(12, 26, 44, 0.6)',
            borderWidth: '0px'
        },
        // Hover state
        '&:hover fieldset': {
            borderColor: 'rgba(12, 26, 44, 0.6)'
        },
        // Focused state
        '&.Mui-focused fieldset': {
            borderColor: 'rgba(12, 26, 44, 0.6)', // Primary color when focused
            borderWidth: '0px',
            color: 'white'
        }
    },

    // Autofill styles
    '& .MuiInputBase-input:-webkit-autofill': {
        '-webkit-box-shadow': '0 0 0 100px rgba(12, 26, 44, 0.6) inset', // Light blue background
        '-webkit-text-fill-color': 'white', // Slightly darker blue text
        caretColor: 'white',
        transition: 'background-color 5000s ease-in-out 0s'
    },

    // For Firefox autofill
    '& .MuiInputBase-input:autofill': {
        background: 'rgba(12, 26, 44, 0.6) !important',
        color: 'white !important'
    },

    // Active/filled styles
    '& .MuiInputBase-root.Mui-focused': {
        backgroundColor: 'rgba(12, 26, 44, 0.6)', // Slightly lighter background when active
        color: 'white'
    },

    // Error styles
    '&.Mui-error .MuiOutlinedInput-root fieldset': {
        borderColor: 'white'
    },
    '&.Mui-error .MuiInputLabel-root': {
        color: 'white'
    },
    '& .MuiInputLabel-root.MuiInputLabel-shrink': {
        // This affects the position of the label when it's shrunk (when field has value or is focused)
        transform: 'translate(5px, -20px) scale(0.75)', // Default is around -9px
        letterSpacing: '1.3px'
    }
});

import React from 'react';
import { IconButton, Tooltip, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ExitToApp } from '@mui/icons-material';

interface LogoutButtonProps {
    onLogout: () => void;
}

export const LogoutButton: React.FC<LogoutButtonProps> = ({ onLogout }) => {
    const navigate = useNavigate();

    const handleLogout = () => {
        onLogout();
        navigate('/login');
    };

    return (
        <Tooltip
            title="Logout"
            placement="top"
            sx={{
                '.MuiTooltip-tooltip': {
                    fontSize: '1.2rem',
                    padding: '8px 12px'
                }
            }}>
            <IconButton
                onClick={handleLogout}
                sx={{
                    position: 'fixed',
                    top: { xs: 'unset', sm: '180px', md: '50px', lg: '50px' },
                    bottom: { xs: '50px', sm: 'unset', md: 'unset', lg: 'unset', xl: 'unset' },
                    left: { xs: '20px', sm: '24px', lg: '40px' },
                    background:
                        'linear-gradient(to bottom, rgba(12, 260, 440, 0.8), rgba(12, 26, 44, 0.9))',
                    color: 'white',
                    borderRadius: { xs: '50%', sm: '10px' },
                    padding: { xs: '20px', sm: '15px' },
                    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                    zIndex: 1000,
                    '&:hover': {
                        backgroundColor: '#d32f2f'
                    }
                }}>
                <ExitToApp sx={{ padding: { xs: '0px', sm: '0px 10px' } }} />
                <Typography sx={{ display: { xs: 'none', sm: 'unset' } }}> Log out </Typography>
            </IconButton>
        </Tooltip>
    );
};

import React from 'react';
import { Container, Grid2 } from '@mui/material';
import { tools } from './tools';
import { ToolCard } from './ToolCard';

export const ToolsSection: React.FC = () => {
    return (
        <Container
            maxWidth="lg"
            sx={{ marginTop: { xs: '100px', md: '40px', lg: '40px' }, padding: '20px' }}>
            <Grid2 container spacing={5} justifyContent="center" alignItems="stretch">
                {tools.map((tool, index) => (
                    <Grid2 size={{ xs: 6, sm: 5, md: 4, lg: 3 }} spacing={10} key={index}>
                        <ToolCard tool={tool} />
                    </Grid2>
                ))}
            </Grid2>
        </Container>
    );
};

import { LOCAL_STORAGE_TOKEN_KEY } from './constants';
import { jwtDecode } from 'jwt-decode';

interface JwtPayload {
    exp: number;
}

export const checkAuth = async (): Promise<boolean> => {
    try {
        const token = localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY);

        if (!token || window.location.pathname === '/login') {
            localStorage.removeItem(LOCAL_STORAGE_TOKEN_KEY);
            return false;
        }
        const decoded: JwtPayload = jwtDecode(token);

        const currentTime = Date.now() / 1000;

        return decoded.exp >= currentTime;
    } catch (error) {
        return false;
    }
};

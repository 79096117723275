import React, { FC } from 'react';
import { AppBar, Box, Typography } from '@mui/material';

export const Header: FC = () => {
    return (
        <AppBar
            position="static"
            sx={{
                background: 'transparent',
                boxShadow: 'none',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }}>
            <Box
                sx={{
                    width: { xs: '95%', md: '50%' },
                    height: { xs: '180px', sm: '120px', md: '120px' },
                    background:
                        'linear-gradient(to bottom, rgba(12, 26, 44, 0.6), rgba(12, 26, 44, 0.6)),center/cover no-repeat',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    textAlign: 'center',
                    borderRadius: '10px',
                    boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.5)',
                    marginTop: { xs: '30px', md: '20px' }
                }}>
                <Typography
                    variant="h2"
                    component="h1"
                    fontFamily={
                        "Avenir, Montserrat, Corbel, 'URW Gothic', source-sans-pro, sans-serif"
                    }
                    letterSpacing={'3px'}
                    fontWeight={'bolder'}>
                    Viper Tools Portal
                </Typography>
            </Box>
        </AppBar>
    );
};

import { API_URL, LOCAL_STORAGE_TOKEN_KEY } from './constants';

interface LoginResponse {
    token?: string;
    error?: string;
}

export const loginRequest = async (email: string, password: string): Promise<LoginResponse> => {
    try {
        const formData = new FormData();
        formData.append('email', email);
        formData.append('password', password);

        const response = await fetch(`${API_URL}is_authenticated/`, {
            method: 'POST',
            body: formData
        });

        if (!response.ok) {
            const errorData = await response.json();
            return { error: errorData.Message ?? 'Something went wrong, please try again.' };
        }

        const data = await response.json();

        localStorage.setItem(LOCAL_STORAGE_TOKEN_KEY, data.JWT);

        return { token: data.JWT };
    } catch (error) {
        return { error: 'Something went wrong, please try again.' };
    }
};

import { Box, CircularProgress } from '@mui/material';
import { FC } from 'react';

export const Loader: FC = () => {
    return (
        <Box
            sx={{
                background: 'linear-gradient(to bottom, rgba(0, 0, 0, 0.4), rgba(12, 26, 44, 0.8))'
            }}
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100vh">
            <CircularProgress color="primary" size={100} />
        </Box>
    );
};

export interface ToolType {
    image: string;
    title: string;
    status: 'active' | 'comingSoon';
    url?: string;
}

export const tools: ToolType[] = [
    {
        image: '/images/viper_grid_traffic.jpeg',
        title: 'Viper GMB Grid Tracker',
        status: 'active',
        url: 'https://viper-ranking.viperseotools.com/#'
    },
    {
        image: '/images/indexer.jpeg',
        title: 'Indexer',
        status: 'active',
        url: 'https://indexer.viperseotools.com'
    },
    {
        image: '/images/powerful_kraken.jpeg',
        title: 'Kraken Viper',
        status: 'active',
        url: 'https://krakken.viperseotools.com/#'
    },
    { image: '/images/megalodon.jpeg', title: 'Megalodon', status: 'comingSoon' },
    { image: '/images/chronos.jpeg', title: 'Project Chronos', status: 'comingSoon' },
    { image: '/images/teleportation.jpeg', title: 'Operation Pegasus', status: 'comingSoon' },
    { image: '/images/viper_vortex.jpeg', title: 'Viper Vortex', status: 'comingSoon' }
];

import React, { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToolsSection } from './cards/CardContainer';
import { Loader } from './Loader';
import { LoginForm } from './LoginForm';
import { Header } from './Header';
import { checkAuth } from '../services/check-auth';
import { MainWrapper } from './MainWrapper';
import { LogoutButton } from './Logout';
import { LOCAL_STORAGE_TOKEN_KEY } from '../services/constants';

export const AppPage: FC = () => {
    const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);
    const navigate = useNavigate();

    useEffect(() => {
        checkAuth().then((res) => {
            setIsAuthenticated(res);
        });
    }, []);

    useEffect(() => {
        if (isAuthenticated) {
            navigate('/');
        }
    }, [isAuthenticated, navigate]);

    const handleLogout = () => {
        localStorage.removeItem(LOCAL_STORAGE_TOKEN_KEY);
        setIsAuthenticated(false);
    };

    if (isAuthenticated === null) {
        return <Loader />;
    }

    return (
        <MainWrapper>
            <Header />
            {!isAuthenticated ? (
                <LoginForm setAuthenticate={setIsAuthenticated} />
            ) : (
                <>
                    <LogoutButton onLogout={handleLogout} />
                    <ToolsSection />
                </>
            )}
        </MainWrapper>
    );
};

import { LOCAL_STORAGE_TOKEN_KEY } from './constants';

export const toolRedirect = (url: string | undefined) => {
    if (!url) {
        return;
    }

    const token = localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY);

    if (token) {
        console.log(`${url}/login/${token}`);
        window.location.href = `${url}/login/${token}`;
    }
};
